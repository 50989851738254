.formWrapper {
  margin: var(--spacing-6) var(--spacing-5);
  @media (--tablet-l) {
    margin: var(--spacing-10) var(--spacing-6);
  }
  @media (--desktop) {
    max-width: 1092px;
    margin: var(--spacing-16) auto;
  }
}

.inputFormTitle {
  margin-top: 0;
}

.valuationWrapper {
  @media (--tablet) {
    margin: 0 var(--spacing-6);
  }

  @media (--tablet-l) {
    margin: var(--spacing-10) var(--spacing-16);
  }
}

.detailsInput {
  gap: var(--spacing-3);
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin: var(--spacing-8) 0 0;

  @media (--tablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.row {
  && {
    align-items: start;
    @media (--mobile) {
      width: 100%;
    }
  }
}

.col {
  margin-bottom: var(--spacing-3);

  &:last-child {
    margin-bottom: 0;
  }

  & > div,
  & > button {
    width: 100%;
  }

  & span[role='alert'] {
    display: relative;
  }

  /* move input errors under tab container */
  @media (--tablet) {
    margin-bottom: 0;
  }
}

.input {
  padding-left: var(--spacing-2);
}

.valuationValue {
  text-align: center;
  @media (--tablet) {
    text-align: start;
  }
  @media (--desktop-l) {
    display: flex;
    gap: var(--spacing-6);
    margin: var(--spacing-6) 0;
    align-items: flex-end;
  }
}

.valuationTitle {
  @media (--tablet-l) {
    font-size: 24px;
  }
  @media (--desktop-l) {
    margin: 0;
  }
}

.valuationFigure {
  color: var(--color-mica-blue-500);
  @media (--tablet) {
    margin-top: 0;
  }
  @media (--tablet-l) {
    font-size: 32px;
  }
  @media (--desktop-l) {
    margin-bottom: 0;
  }
}

.valuationCtasContainer {
  @media (--desktop-l) {
    display: flex;
    gap: var(--spacing-4);
  }
}

.valuationCTA {
  margin-bottom: var(--spacing-2);
  @media (--desktop-l) {
    flex: 1;
    width: 100%;
  }
}

.vehicleCard {
  background-color: var(--color-tarmac-grey-50);
  height: 100%;
  margin-top: var(--spacing-6);

  & img {
    width: 100%;
  }

  @media (--tablet) {
    margin: var(--spacing-6) 0;
  }
}

.bodyText {
  margin-bottom: var(--spacing-6);
}

.vehicleCardInformation {
  padding: 18px var(--spacing-5);
  box-sizing: border-box;
  @media (--tablet) {
    padding: 18px var(--spacing-5) 0 var(--spacing-5);
  }
}

.variant {
  line-height: var(--spacing-5);
  margin: 8px auto;
}

.labels {
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing-2);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  margin: var(--spacing-4) 0;
}

.notYourCar {
  display: flex;
  color: var(--color-mica-blue-500);
  min-height: initial;

  & span {
    font-size: 14px;
  }

  @media (--tablet) {
    display: none;
  }
}

.notYourCarTablet {
  display: none;
  min-height: initial;

  & span {
    font-size: 14px;
  }

  @media (--tablet) {
    display: flex;
    color: var(--color-mica-blue-500);
    padding: 2px 2px;
    justify-content: center;
    align-items: flex-start;
    font-size: 14px;
  }
}

.footer {
  margin: var(--spacing-6) 0;
  @media (--desktop-l) {
    display: none;
  }
}

.footerDesktop {
  display: none;
  @media (--desktop-l) {
    display: flex;
    margin: var(--spacing-6) 0;
  }
}

.pageLoader {
  height: 300px;
  position: relative;
}

.imageContainer {
  & img {
    object-fit: contain;
    width: 100%;
    height: auto;
  }
}
