.modalWrapper {
  padding: 10px 0 24px 0;
  @media (--tablet-l) {
    min-width: 800px;
  }
}

.modalTitle {
  @media (--tablet-l) {
    text-align: center;
  }
}

.uspList {
  list-style-type: none;
  padding-left: 0;
}

.uspListRow {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.redirectError {
  margin-top: 64px;
  text-align: center;
}

.logo {
  margin: 64px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
